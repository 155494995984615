/* eslint-disable no-unused-vars */
import React from "react";
import "../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import DULogo from "../resources/du.png";
import { useGlobalState } from "./UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Navbar = () => {
  const nav = useNavigate();
  var mobile = window.matchMedia("(max-width: 700px)");

  const [user] = useGlobalState("user");
  let location = useLocation();
  return (
    <div className="bg-[#002147] relative px-2 sm:px-4 py-2.5 w-full">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <div onClick={() => nav("/home")} className="flex items-center">
          <img
            src={DULogo}
            className="h-6 mr-3 sm:h-9 cursor-pointer"
            alt="DU Logo"
          />
          <span className="self-center text-xl font-semibold whitespace-nowrap cursor-pointer text-white">
            PMICS Admission
          </span>
        </div>

        <div className="flex flex-row items-center gap-4">
          <div
            onClick={() => nav("/submission")}
            className="menu-item cursor-pointer text-white"
          >
            {user.role === "admin" ? "Submissions" : "My Submissions"}
          </div>

          {user.role === "student" ? (
            <button
              type="button"
              onClick={() => {
                user.profile !== "1"
                  ? nav("/profile", { state: "incomplete" })
                  : toast.error("Deadline over. Applications are closed.");
                  //  : nav("/application");
              }}
              className={`text-[#002147] bg-white hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm ${
                mobile.matches ? "px-3 py-1" : "px-5 py-2.5"
              } text-center mr-14 md:mr-0 ${
                location.pathname === "/application" ? "invisible" : "block"
              }`}
            >
              Apply Now
            </button>
          ) : user.role === "admin" ? (
            <button
              type="button"
              onClick={() => nav("/admin")}
              className={`text-[#002147] bg-white hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm ${
                mobile.matches ? "px-3 py-1" : "px-5 py-2.5"
              } text-center mr-14 md:mr-0`}
            >
              Admin Panel
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
